
require('normalize.css/normalize.css');
require('./styles/index.scss');



document.addEventListener("DOMContentLoaded", () => {


    var random = Math.floor(Math.random() * 6) + 0;
    var bigSize = [`url('//redsoilorganics.ca/images/1.jpg')`,
        `url('//redsoilorganics.ca/images/2.jpg')`,
        `url('//redsoilorganics.ca/images/3.jpg')`,
        `url('//redsoilorganics.ca/images/4.jpg')`,
        `url('//redsoilorganics.ca/images/5.jpg')`,
        `url('//redsoilorganics.ca/images/6.jpg')`];
    document.getElementById("mainbody").style.backgroundImage = bigSize[random];
});
